.headingH2 {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
}
.headingH6Regular {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}

.text-black-60 {
  color: #676767;
}
.text-center {
  text-align: center !important;
}
.Contact_us {
  margin-top: 15px;
  padding: 11px 36px;
  gap: 10px;
  border-radius: 41px;
  opacity: 1; /* Set to 1 if you want it visible */
  background: #003285;
}

.Contact_us_Link {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #ffffff; /* Remove quotes */
}

.chip-box {
  border: 1px solid #1dbad0;
  padding: 4px 3px 4px 3px;
  color: #1dbad0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.75px;
  text-align: center;
  width: max-content;
  border-radius: 22px;
}
.who_are_content{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: justify;
    color:#676767;
}
.expertise-card{
    border: 0px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    text-align: center;
    padding: 20px 14px 20px 14px;
    background: radial-gradient(166.51% 85.34% at 49.11% 49.08%, #E9D8FF -200%, rgba(255, 255, 255, 0) 26%, rgba(233, 216, 255, 0) 6%) 
}
.expertise-card:not(:last-child) {
    
    border-right: 2px solid #ffffff;
    margin-right: 10px;
}
.expertise-box {
    padding: 47px 65px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 22px;
    background: linear-gradient(180deg, #325da4 0%, #003285 100%);
}

.expertise-card {
    flex: 1 1 calc(20% - 20px); /* Five columns on desktop */
    max-width: calc(20% - 20px);
    background: transparent;
    text-align: center;
    padding: 16px;
    position: relative;
    border-right: 2px solid white; /* Vertical border between items */
}

.expertise-box .expertise-card:nth-child(5n) {
    border-right: none; /* Remove border for the last item in a row */
}

/* For screens between 1024px and 1202px */
@media (max-width: 1202px) and (min-width: 1024px) {
    .expertise-card {
        flex: 1 1 calc(25% - 20px); /* Four items per row */
        max-width: calc(25% - 20px);
    }

    .expertise-box .expertise-card:nth-child(4n) {
        border-right: none; /* Remove border for the last item in rows with 4 items */
    }

    .expertise-box .expertise-card:nth-child(5n) {
        border-right: 2px solid white; /* Reset border for five-item layout */
    }
}

@media (max-width: 1024px) { /* Adjust for smaller screens (tablet) */
    .expertise-card {
        flex: 1 1 calc(33.33% - 20px); /* Three columns */
        max-width: calc(33.33% - 20px);
    }

    .expertise-box .expertise-card:nth-child(5n) {
        border-right: 2px solid white; /* Reset border for smaller screens */
    }

    .expertise-box .expertise-card:nth-child(3n) {
        border-right: none; /* Remove border for the last item in a row */
    }
}

@media (max-width: 768px) { /* Mobile: Two columns */
    .expertise-card {
        flex: 1 1 calc(50% - 20px); /* Two columns */
        max-width: calc(50% - 20px);
        border-right: none; /* Default: No border */
        /* border-bottom: 2px solid white; */
    }

    .expertise-box .expertise-card:nth-child(2n) {
        border-right: 2px solid white; /* Add border for even items */
    }

    .expertise-box .expertise-card:nth-child(2n + 0) {
        border-right: none; /* Remove border for odd items */
    }
}

@media (max-width: 480px) { /* Extra small screens */
    .expertise-card {
        flex: 1 1 100%;
        max-width: 100%;
        border: none;
    }
    .expertise-box .expertise-card:nth-child(5n) {
        border-right: 2px solid white; /* Reset border for smaller screens */
    }

    .expertise-box .expertise-card:nth-child(1n) {
        border-right: none; /* Remove border for the last item in a row */
    }
}


.section-Title-why-choose-us{
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 50px;
}
.management_team_feedback {
  padding: 48px 20px 30px 20px;
  gap: 30px;
  border-radius: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
  max-width: 35%;
  flex-basis: 35%;
}

.team_feedback_content {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #676767;
}

.hr-line-subscription {
  border: 1px solid #a4a4a44f;
  margin-top: 20px;
}
.subscriptionCard {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 18px;
  padding-right: 18px;
  background-color: #ffff;
  border-radius: 26px;
}
.planDescriptionPoint {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  color: #1b223c;
}
.planType-title {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1px;
  text-align: left;
}
.my-plan-price{
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -1px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}
.plan-end-date-section{
  width: 397px;
height: 98px;
padding: 12px 0px 12px 0px;
gap: 12px;
border-radius: 12px 0px 0px 0px;
display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
background: #F4F4F4;
width: auto;

}
.section-my-plan-sidebar{
  margin-left: 20px;
  margin-right: 20px;
}
.free-box {
  height: 64px;
  width: 56px;
  border: 0.09px solid;
  border-radius: 16px;
  width: fit-content;
  padding: 16px 12px 16px 12px;
  border-image-source: radial-gradient(
    103.58% 103.58% at 50% 50%,
    rgba(181, 194, 251, 0) 4.69%,
    #b5c2fb 100%
  );
  background: linear-gradient(180deg, #325DA4 0%, #003285 100%);
}
.free-box-my-plan-emp{
  border: 0.09px solid;
  border-radius: 100%;
  width: fit-content;
  padding: 60px;
  border-color: #14A6BA;
}
.free-box-my-plan {
  border: 0.09px solid;
  border-radius: 100%;
  width: fit-content;
  padding: 60px;
  border-image-source: radial-gradient(
    103.58% 103.58% at 50% 50%,
    rgba(181, 194, 251, 0) 4.69%,
    #b5c2fb 100%
  );
  background: linear-gradient(180deg, #325DA4 0%, #003285 100%);
}
.basic-box-theme{
  border: 0.09px solid;
  border-radius: 100%;
  width: fit-content;
  padding: 60px;
  border-color: rgba(0, 50, 133, 1);
  color: rgba(0, 50, 133, 1);
}
.premium-box-theme{
  border: 0.09px solid;
  border-radius: 100%;
  width: fit-content;
  padding: 60px;
  border-color: rgba(247, 173, 20);
}

.jobseeker-plan-type{
  font-family: Manrope;
font-size: 14px;
font-weight: 700;
line-height: 19.12px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: rgba(0, 50, 133, 1);

}

.paid-box-icon {
  height: 64px;
  width: 56px;
  border: 0.09px solid;
  border-radius: 16px;
  /* width: fit-content; */
  padding: 16px 12px 16px 12px;
  border-image-source: radial-gradient(
    103.58% 103.58% at 50% 50%,
    rgba(181, 194, 251, 0) 4.69%,
    #b5c2fb 100%
  );
  /* background: linear-gradient(180deg, #325DA4 0%, #003285 100%); */
  background: linear-gradient(180deg, rgba(218, 226, 255, 0.56) 0%, rgba(255, 255, 255, 0.2) 100%);

}
.current-plan{
    border: 1px solid #969696;
    border-radius: 9px;
    padding: 5px 10px 5px 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #717171;
    background-color: #54545414;
}
.buyNow-btn{
    box-shadow: 0px 8px 36px 0px #1B223C29;
    border: 1px solid;
    background: linear-gradient(180deg, #325DA4 0%, #003285 100%);
    width:100%;
    padding-top: 12px;
    padding-bottom: 12px;
    /* gap: 10px; */
    color:#ffff;
    border-radius: 18px;
    border: 1px 0px 0px 0px;
    /* opacity: 0px; */
    /* border-image-source: linear-gradient(99.18deg, #152C80 28.84%, rgba(21, 44, 128, 0) 85.64%); */
}
.cancel-btn{
  box-shadow: 0px 8px 36px 0px #1B223C29;
  border: 1px solid;
  color: linear-gradient(180deg, #325DA4 0%, #003285 100%);
  width:100%;
  padding-top: 12px;
  padding-bottom: 12px;
  /* gap: 10px; */
  /* color:#ffff; */
  border-radius: 18px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  /* border-image-source: linear-gradient(99.18deg, #152C80 28.84%, rgba(21, 44, 128, 0) 85.64%); */
}
.getStarted-btn{
  box-shadow: 0px 8px 36px 0px #1B223C29;
  /* border: 1px solid; */
  /* background: linear-gradient(180deg, #325DA4 0%, #003285 100%); */
  width:100%;
  padding-top: 12px;
  padding-bottom: 12px;
  /* gap: 10px; */
  /* color:#ffff; */
  color: #1B223C;
  border-radius: 18px;
  /* border: 1px 0px 0px 0px; */
  /* opacity: 0px; */
  border: 1px solid #1B223C;
  background-color: #ffff;
  /* border-image-source: linear-gradient(99.18deg, #152C80 28.84%, rgba(21, 44, 128, 0) 85.64%); */
}
.tabs-container {
  display: flex;
  background: #ffffff;
  border-radius: 20px;
  padding: 5px;
  width: fit-content;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  color: #333;
  border-radius: 20px;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
}

.tab.active {
  background-color: #0f3984; /* Your desired blue color */
  color: white;
}
.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns by default */
  column-gap: 17px; /* Space between columns */
  row-gap: 17px; /* Space between rows */
}
.knowCredits{
  display: none;
}

@media (max-width: 800px) {
  .knowCredits{
    display: flex;
  }
}

@media (max-width: 1024px) {
  .cards-container {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }
}

@media (max-width: 600px) {
  .cards-container {
      grid-template-columns: 1fr; /* 1 column for small screens */
  }
}

.model-body-payment{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 130px;
  padding-bottom: 130px;
}

.right-sidebar {
  background-color: #f8f9fa;
  padding: 15px;
  border-left: 1px solid #ddd;
}

.main-content {
  padding: 15px;
}
.offcanvas-body {
  padding: 20px;
  background-color: #f8f9fa;
}

.offcanvas .nav-link {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.offcanvas .nav-link:hover {
  text-decoration: underline;
  color: #007bff;
}
.my-plan {
  position: relative; /* Ensure it stays within flow */
  z-index: 1000000000000000; /* Bring it above other overlapping elements */
  cursor: pointer; /* Display pointer cursor for clarity */
  border: none;
  background: none;
}
.my-plan:hover {
  color: #98BE45;
  background-color: transparent;
}
/* Blur overlay for background */
.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent dark background */
  backdrop-filter: blur(5px); /* Blur effect */
  z-index: 1045; /* Ensure it appears under the sidebar but above the content */
}

/* Ensure content isn't affected */
.dropdown-item {
  position: relative;
  z-index: 1050; /* Ensure above the blur layer */
}
.my-plan-title{
  font-family: Poppins;
font-size: 32px;
font-weight: 500;
line-height: 48px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #003285;

}
.total-title{
  font-weight: 600;
  font-size: 14px;
  color:#979797;

}
.confirmation-amount{
  font-weight: 500;
  font-size: 40px;
  letter-spacing: -1px;
}
.for-subscription-title{
  font-weight: 600;
  font-size: 12px;
  color: #4D4D4F;

}
.h-min{
  height: min-content !important;
}

.custom-modal-jobCreds .modal-content {
  background: transparent; /* Makes modal background transparent */
  border: none; /* Optional: remove border */
  box-shadow: none; /* Optional: remove shadow */
}

.custom-modal-jobCreds .modal-backdrop {
  background: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent backdrop */
}
.point-text{
  margin-left: 10px;
}
.custom-modal-width {
  max-width: 600px; /* Default desktop modal width */
}

.last-section{
  gap: 20px;
}
.box-confirmation{
  height: auto;
}
.back-btn{
  display: none;
}
.cross-btn-header{
  display: block;
}
@media (max-width: 768px) {
  .full-screen-mobile {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  .full-screen-mobile .modal-content{
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  .back-btn{
    display: block;
  }
  .cross-btn-header{
    display: none;
  }
  .last-section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .box-confirmation{
    height: calc(100vh - 242px);
  }
  .custom-modal-width {
    max-width: 100vw;
  }
}

.card-category-box {
    border-radius: 10px;
    overflow: hidden;
  }
  
  .card-category-box-img-overlay {
    height: 100%;
    padding-bottom: 10px;
    padding-left: 10px;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
  }
  .partner-box{
    background-color: #ffff;
    width: 400px;
    height: 200px;
    align-items: center;
    border-radius: 9px;
    overflow: hidden;
  }
  .card-for-home{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .sectionsTwo-aboutus {
    display: flex !important;
    flex-direction: row;
}
.sectiontwo-imag {
   width: 50%;
}

@media (max-width: 768px) { /* For tablets and smaller screens */
    .sectionsTwo-aboutus {
        flex-direction: column;
    }
    .sectiontwo-imag{
        width: 100%;
     }
}
.card {
    flex: 1;
    margin: 0 10px;

  }
  
  .whoWeAreSection {
    background-color: #ffffff;
    padding: 36px 22px;
    max-width: 90%;
    width: 100%;
    border-radius: 22px;
    display: flex;
    gap: 50px;
    flex-direction: column-reverse; /* Default for mobile */
    align-items: center;
}

.whoWeAreSection img {
    width: 100%; /* Adjust image width for mobile */
}

@media (min-width: 768px) { /* Adjust breakpoint as needed */
    .whoWeAreSection {
        flex-direction: row; /* Row for desktop */
        justify-content: space-between; /* Optional for alignment */
    }
    .whoWeAreSection img {
        width: 50%; /* Reset image width for desktop */
        order: 2; /* Ensure image is on the right */
    }
}
